import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import FunctionsPageTemplate from '../components/Functions/FunctionsPageTemplate';
import MainSection from '../components/Functions/MainSection';
import main from '../assets/images/functions/cookies/main.png';
import main2x from '../assets/images/functions/cookies/main_2x.png';
import headLeft from '../assets/images/functions/cookies/head_left.png';
import headRight from '../assets/images/functions/cookies/head_right.png';
import HeadSection from '../components/Functions/HeadSection';
import PossibilitiesSection from '../components/Functions/PossibilitiesSection';
import first from '../assets/images/functions/cookies/1.png';
import first2x from '../assets/images/functions/cookies/1_2x.png';
import second from '../assets/images/functions/cookies/2.png';
import second2x from '../assets/images/functions/cookies/2_2x.png';
import third from '../assets/images/functions/cookies/3.png';
import third2x from '../assets/images/functions/cookies/3_2x.png';
import fourth from '../assets/images/functions/cookies/4.png';
import fourth2x from '../assets/images/functions/cookies/4_2x.png';
import fifth from '../assets/images/functions/cookies/5.png';
import fifth2x from '../assets/images/functions/cookies/5_2x.png';
import ImageContainer from '../components/ImageContainer/ImageContainer';
import BlueSpan from '../components/common/typography/BlueSpan';

const faqs = [
  {
    question: 'Where can I see the results of the Cookie robot’s work?',
    answer:
      'To see the cookies collected by the robot, you can enter chrome://settings/siteData in the address bar of the active profile.',
  },
  {
    question:
      'If the cookies have been added already, but I want to add new ones, will the old ones be deleted?',
    answer:
      'Cookies will be added if there were no cookies from the specific service before; otherwise they will be overwritten.',
  },
  {
    question: 'How do I clear cookies from my profile?',
    answer:
      'You can do this in two ways: first, you can clone your profile. The resulting profile will have no cookies or descriptions. Second, you can clear cookies through Chrome (chrome://settings/siteData / Remove all).',
  },
  {
    question: 'Which cookie formats can I use?',
    answer: 'You can use cookies in Netscape and Json formats.',
  },
  {
    question: 'Can I use the Cookie robot with a profile with cookies?',
    answer: 'Collected cookies will be added to the already existing ones.',
  },
].map((item) => ({
  question: <Trans>{item.question}</Trans>,
  answer: <Trans>{item.answer}</Trans>,
}));

const cardsInfo = [
  {
    title: 'Cookie import and export',
    description:
      'Work with cookie files in Json and Netscape formats. You can also drag-and-drop the cookie files.',
    icon: <ImageContainer src={first} srcSet={{ _2x: first2x }} />,
  },
  {
    title: 'Cookie collection automation',
    description:
      'Our Cookie robot will help you collect cookies automatically.',
    icon: <ImageContainer src={second} srcSet={{ _2x: second2x }} />,
  },
  {
    title: 'Setting up the Cookie robot',
    description:
      'You can import an unlimited amount of links into the Cookie robot.',
    icon: <ImageContainer src={third} srcSet={{ _2x: third2x }} />,
  },
  {
    title: 'Link validity',
    description:
      'The Cookie robot checks link validity, and rejects invalid links.',
    icon: <ImageContainer src={fourth} srcSet={{ _2x: fourth2x }} />,
  },
  {
    title: 'Profile preferences',
    description:
      'You can turn saving cookies on and off when needed in your profile settings.',
    icon: <ImageContainer src={fifth} srcSet={{ _2x: fifth2x }} />,
  },
];

const CookiesPage = (): JSX.Element => (
  <FunctionsPageTemplate faq={faqs}>
    <HeadSection
      description="Every profile in Octo Browser has its own cookie files that don’t look suspicious when the fingerprint is checked by websites."
      leftBgImage={headLeft}
      rightBgImage={headRight}
      title="Cookies"
    />
    <MainSection
      description="Increase your profile security when importing cookie files. Automate cookie collection using our Cookie robot working in the multithreaded headless mode."
      image={main}
      image2x={main2x}
      title={
        <Trans i18nKey="Use cookies for authentic profiles">
          Use cookies <BlueSpan>for authentic profiles</BlueSpan>
        </Trans>
      }
    />
    <PossibilitiesSection
      cards={cardsInfo}
      title={
        <Trans i18nKey="Cookie features">
          Cookie <BlueSpan>features</BlueSpan>
        </Trans>
      }
    />
  </FunctionsPageTemplate>
);

export default CookiesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["cookiesPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
